@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 100;
}
.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 16px;
}
h4 {
  font-size: 14px;
}
h5 {
  font-size: 12px;
}
h6 {
  font-size: 10px;
}
h3,
h4,
h5 {
  margin-top: 5px;
  font-weight: 600;
}
.nav > li > a {
  color: #a7b1c2;
  font-weight: 600;
  padding: 14px 20px 14px 25px;
  display: block;
}
.nav.metismenu > li {
  display: block;
  width: 100%;
  position: relative;
}
.nav.metismenu .dropdown-menu > li > a {
  padding: 3px 20px;
  display: block;
}
.nav.navbar-right > li > a {
  color: #999c9e;
}
.nav > li.active > a {
  color: #ffffff;
}
.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus {
  background-color: #293846;
  color: white;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background: #fff;
}
.nav.navbar-top-links > li > a:hover,
.nav.navbar-top-links > li > a:focus {
  background-color: transparent;
}
.nav > li > a i {
  margin-right: 6px;
}
.navbar {
  border: 0;
}
.navbar-default {
  background-color: transparent;
  border-color: #2f4050;
}
.navbar-top-links li {
  display: inline-block;
  align-self: center;
}
.body-small .navbar-top-links li:last-child {
  margin-right: 0;
}
.navbar-top-links li a {
  padding: 20px 10px;
  min-height: 50px;
}
.dropdown-menu {
  border: medium none;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  display: none;
  float: left;
  font-size: 12px;
  left: 0;
  list-style: none outside none;
  padding: 0;
  position: absolute;
  text-shadow: none;
  top: 100%;
  z-index: 1000;
}
.dropdown-menu > li > a {
  border-radius: 3px;
  color: inherit;
  line-height: 25px;
  margin: 4px;
  text-align: left;
  font-weight: normal;
  display: block;
  padding: 3px 20px;
}
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1ab394;
  outline: 0;
}
.dropdown-menu > li > a.font-bold {
  font-weight: 600;
}
.navbar-top-links .dropdown-menu li {
  display: block;
}
.navbar-top-links .dropdown-menu li:last-child {
  margin-right: 0;
}
.navbar-top-links .dropdown-menu li a {
  padding: 3px 20px;
  min-height: 0;
}
.navbar-top-links .dropdown-menu li a div {
  white-space: normal;
}
.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
  width: 310px;
  min-width: 0;
}
.navbar-top-links .dropdown-messages {
  margin-left: 5px;
}
.navbar-top-links .dropdown-tasks {
  margin-left: -59px;
}
.navbar-top-links .dropdown-alerts {
  margin-left: -123px;
}
.navbar-top-links .dropdown-user {
  right: 0;
  left: auto;
}
.dropdown-messages,
.dropdown-alerts {
  padding: 10px 10px 10px 10px;
}
.dropdown-messages li a,
.dropdown-alerts li a {
  font-size: 12px;
}
.dropdown-messages li em,
.dropdown-alerts li em {
  font-size: 10px;
}
.nav.navbar-top-links .dropdown-alerts a {
  font-size: 12px;
}
.nav-header {
  padding: 33px 25px;
  background-color: #2f4050;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.profile-element .dropdown-toggle::after {
  display: none;
}
.pace-done .nav-header {
  transition: all 0.4s;
}
ul.nav-second-level {
  /*background: #293846;*/
}
.nav > li.active {
  border-left: 4px solid #19aa8d;
  background: #293846;
}

.CustomMenu.nav > li:hover { 
  background: #293846;
}

.nav.nav-second-level > li.active {
  border: none;
}
.nav.nav-second-level.collapse[style] {
  height: auto !important;
}
.nav-header a {
  color: #DFE4ED;
}
.nav-header .text-muted {
  color: #8095a8 !important;
}
.minimalize-styl-2 {
  padding: 4px 12px;
  margin: 14px 5px 5px 20px;
  font-size: 14px;
  float: left;
}
.navbar-form-custom {
  float: left;
  height: 50px;
  padding: 0;
  width: 200px;
  display: block;
}
.navbar-form-custom .form-group {
  margin-bottom: 0;
}
.nav.navbar-top-links a {
  font-size: 14px;
}
.navbar-form-custom .form-control {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: medium none;
  font-size: 14px;
  height: 60px;
  margin: 0;
  z-index: 2000;
}
.nav.navbar-top-links .dropdown-toggle::after {
  display: none;
}
.navbar.navbar-static-top {
  padding: 0;
  width: 100%;
  align-items: inherit;
}
.navbar-static-top .dropdown-menu {
  right: 0;
  left: auto;
}
.count-info .label {
  line-height: 12px;
  padding: 2px 5px;
  position: absolute;
  right: 6px;
  top: 12px;
}
.arrow {
  float: right;
}
.fa.arrow:before {
  content: "\f104";
}
.active > a > .fa.arrow:before {
  content: "\f107";
}
.nav-second-level li,
.nav-third-level li {
  border-bottom: none !important;
}
.nav.nav-third-level > li.active {
  border: none;
}
.nav-second-level li a {
  padding: 10px 20px;
  font-size: 14px; 
}
.fixed-sidebar.mini-navbar .nav-second-level.collapsing li a,
.nav-second-level.collapsing li a {
  min-width: 220px;
}
.body-small .nav-second-level.collapsing li a,
.mini-navbar .nav-second-level.collapsing li a {
  min-width: 140px;
}
.nav-third-level li a,
.fixed-sidebar.mini-navbar .nav-second-level li .nav-third-level li a {
  padding-left: 62px;
}
.nav-second-level li:last-child {
  /*padding-bottom: 10px;*/
}
body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav li:hover > .nav-second-level,
.mini-navbar .nav li:focus > .nav-second-level {
  display: block;
  border-radius: 0 2px 2px 0;
  min-width: 160px;
  height: auto;
}
body.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
  font-size: 12px;
  border-radius: 3px;
}
.fixed-nav .slimScrollDiv #side-menu {
  padding-bottom: 60px;
}
.mini-navbar .nav-second-level li a {
  padding: 10px 10px 10px 15px;
}
.mini-navbar .nav .nav-second-level {
  position: absolute;
  left: 70px;
  top: 0;
  background-color: #2f4050;
  padding: 10px 10px 10px 10px;
  font-size: 12px;
}
.canvas-menu.mini-navbar .nav-second-level {
  background: #293846;
}
.mini-navbar li.active .nav-second-level {
  left: 65px;
}
.navbar-default .special_link a {
  background: #1ab394;
  color: white;
}
.navbar-default .special_link a:hover {
  background: #17987e !important;
  color: white;
}
.navbar-default .special_link a span.label {
  background: #fff;
  color: #1ab394;
}
.navbar-default .landing_link a {
  background: #1cc09f;
  color: white;
}
.navbar-default .landing_link a:hover {
  background: #1ab394 !important;
  color: white;
}
.navbar-default .landing_link a span.label {
  background: #fff;
  color: #1cc09f;
}
.logo-element {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
  display: none;
  padding: 18px 0;
}
.navbar-static-side {
  transition: width 0s;
}
.footer {
  transition: margin 0s;
}
.pace-done .navbar-static-side,
.pace-done .nav-header,
.pace-done li.active,
.pace-done #page-wrapper,
.pace-done .footer {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.navbar-fixed-top {
  background: #fff;
  transition-duration: 0.4s;
  border-bottom: 1px solid #e7eaec !important;
  z-index: 2030;
  position: fixed;
  right: 0;
  left: 0;
  padding: 0;
  top: 0;
}
.navbar-fixed-top .navbar-form-custom .form-control {
  height: 50px;
}
.navbar-fixed-top,
.navbar-static-top {
  background: #f3f3f4;
}
.fixed-nav #wrapper {
  margin-top: 0;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none;
  border-color: #dddddd #dddddd rgba(0, 0, 0, 0);
  border-bottom: #f3f3f4;
  border-image: none;
  border-style: solid;
  border-width: 1px;
  color: #555555;
  cursor: default;
}
.nav.nav-tabs li {
  background: none;
  border: none;
}
body.fixed-nav #wrapper .navbar-static-side,
body.fixed-nav #wrapper #page-wrapper {
  margin-top: 60px;
}
body.top-navigation.fixed-nav #wrapper #page-wrapper {
  margin-top: 0;
}
body.fixed-nav.fixed-nav-basic .navbar-fixed-top {
  left: 220px;
}
body.fixed-nav.fixed-nav-basic.mini-navbar .navbar-fixed-top {
  left: 70px;
}
body.fixed-nav.fixed-nav-basic.fixed-sidebar.mini-navbar .navbar-fixed-top {
  left: 0;
}
body.fixed-nav.fixed-nav-basic #wrapper .navbar-static-side {
  margin-top: 0;
}
body.fixed-nav.fixed-nav-basic.body-small .navbar-fixed-top {
  left: 0;
}
body.fixed-nav.fixed-nav-basic.fixed-sidebar.mini-navbar.body-small .navbar-fixed-top {
  left: 220px;
}
.fixed-nav .minimalize-styl-2 {
  margin: 10px 5px 5px 15px;
}
.body-small .navbar-fixed-top {
  margin-left: 0;
}
body.mini-navbar .navbar-static-side {
  width: 70px;
}
body.mini-navbar .profile-element,
body.mini-navbar .nav-label,
body.mini-navbar .navbar-default .nav li a span {
  display: none;
}
body.canvas-menu .profile-element {
  display: block;
}
body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav-second-level {
  display: none;
}
body.mini-navbar .navbar-default .nav > li > a {
  font-size: 16px;
}
body.mini-navbar .logo-element {
  display: block;
}
body.canvas-menu .logo-element {
  display: none;
}
body.mini-navbar .nav-header {
  padding: 0;
  background-color: #1ab394;
}
body.canvas-menu .nav-header {
  padding: 33px 25px;
}
body.canvas-menu .sidebar-collapse li {
  width: 100%;
}
body.mini-navbar #page-wrapper {
  width: calc(100%);
}
body.canvas-menu.mini-navbar #page-wrapper,
body.canvas-menu.mini-navbar .footer {
  margin: 0;
  width: 100%;
}
body.fixed-sidebar .navbar-static-side,
body.canvas-menu .navbar-static-side {
  width: 220px;
  z-index: 2001;
  height: 100vh;
  position: fixed;
}
body.fixed-sidebar.mini-navbar .navbar-static-side {
  width: 0;
}
body.fixed-sidebar #page-wrapper {
  margin: 0 0 0 220px;
}
body.fixed-sidebar.body-small #page-wrapper {
  margin: 0;
}
body.fixed-sidebar.mini-navbar #page-wrapper {
  margin: 0 0 0 0;
  width: 100%;
}
body.body-small.fixed-sidebar.mini-navbar #page-wrapper {
  margin: 0 0 0 220px;
}
body.body-small.fixed-sidebar.mini-navbar .navbar-static-side {
  width: 220px;
}
.fixed-sidebar.mini-navbar .nav li:focus > .nav-second-level,
.canvas-menu.mini-navbar .nav li:focus > .nav-second-level {
  display: block;
  height: auto;
}
body.fixed-sidebar.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
  font-size: 12px;
  border-radius: 3px;
}
body.canvas-menu.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
  font-size: 13px;
  border-radius: 3px;
}
.fixed-sidebar.mini-navbar .nav-second-level li a,
.canvas-menu.mini-navbar .nav-second-level li a {
  padding: 10px 10px 10px 15px;
}
.fixed-sidebar.mini-navbar .nav-second-level,
.canvas-menu.mini-navbar .nav-second-level {
  position: relative;
  padding: 0;
  font-size: 13px;
}
.fixed-sidebar.mini-navbar li.active .nav-second-level,
.canvas-menu.mini-navbar li.active .nav-second-level {
  left: 0;
}
body.fixed-sidebar.mini-navbar .navbar-default .nav > li > a,
body.canvas-menu.mini-navbar .navbar-default .nav > li > a {
  font-size: 13px;
}
body.fixed-sidebar.mini-navbar .nav-label,
body.fixed-sidebar.mini-navbar .navbar-default .nav li a span,
body.canvas-menu.mini-navbar .nav-label,
body.canvas-menu.mini-navbar .navbar-default .nav li a span {
  display: inline;
}
body.canvas-menu.mini-navbar .navbar-default .nav li .profile-element a span {
  display: block;
}
.canvas-menu.mini-navbar .nav-second-level li a,
.fixed-sidebar.mini-navbar .nav-second-level li a {
  padding: 7px 10px 7px 52px;
}
.fixed-sidebar.mini-navbar .nav-second-level,
.canvas-menu.mini-navbar .nav-second-level {
  left: 0;
}
body.canvas-menu nav.navbar-static-side {
  z-index: 2001;
  background: #2f4050;
  height: 100%;
  position: fixed;
  display: none;
}
body.canvas-menu.mini-navbar nav.navbar-static-side {
  display: block;
  width: 220px;
}
.top-navigation #page-wrapper {
  width: 100%;
}
.top-navigation .navbar-nav .dropdown-menu > .active > a {
  background: white;
  color: #1ab394;
  font-weight: bold;
}
.white-bg .navbar-fixed-top,
.white-bg .navbar-static-top {
  background: #fff;
}
.top-navigation .navbar {
  margin-bottom: 0;
}
.top-navigation .nav > li > a {
  padding: 15px 20px;
  color: #676a6c;
}
.top-navigation .nav > li a:hover,
.top-navigation .nav > li a:focus {
  background: #fff;
  color: #1ab394;
}
.top-navigation .navbar .nav > li.active {
  background: #fff;
  border: none;
}
.top-navigation .nav > li.active > a {
  color: #1ab394;
}
.top-navigation .navbar-right {
  margin-right: 10px;
}
.top-navigation .navbar-nav .dropdown-menu {
  box-shadow: none;
  border: 1px solid #e7eaec;
}
.top-navigation .dropdown-menu > li > a {
  margin: 0;
  padding: 7px 20px;
}
.navbar .dropdown-menu {
  margin-top: 0;
}
.top-navigation .navbar-brand {
  background: #1ab394;
  color: #fff;
  padding: 15px 25px;
  font-size: 18px;
  line-height: 20px;
}
.top-navigation .navbar-top-links li:last-child {
  margin-right: 0;
}
.top-navigation.mini-navbar #page-wrapper,
.top-navigation.body-small.fixed-sidebar.mini-navbar #page-wrapper,
.mini-navbar .top-navigation #page-wrapper,
.body-small.fixed-sidebar.mini-navbar .top-navigation #page-wrapper,
.canvas-menu #page-wrapper {
  margin: 0;
  width: 100%;
}
.top-navigation.fixed-nav #wrapper,
.fixed-nav #wrapper.top-navigation {
  margin-top: 50px;
}
.top-navigation .footer.fixed {
  margin-left: 0 !important;
}
.top-navigation .wrapper.wrapper-content {
  padding: 40px;
}
.top-navigation.body-small .wrapper.wrapper-content,
.body-small .top-navigation .wrapper.wrapper-content {
  padding: 40px 0 40px 0;
}
.navbar-toggler {
  background-color: #1ab394;
  color: #fff;
  padding: 6px 12px;
  font-size: 14px;
  margin: 8px;
}
.top-navigation .navbar-nav .open .dropdown-menu > li > a,
.top-navigation .navbar-nav .open .dropdown-menu .dropdown-header {
  padding: 10px 15px 10px 20px;
}
@media (max-width: 768px) {
  .top-navigation .navbar-header {
    display: block;
    float: none;
  }
}
.menu-visible-lg,
.menu-visible-md {
  display: none !important;
}
@media (min-width: 1200px) {
  .menu-visible-lg {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .menu-visible-md {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .menu-visible-md {
    display: block !important;
  }
  .menu-visible-lg {
    display: block !important;
  }
}
button:focus {
  outline: 0 !important;
}
.btn {
  border-radius: 3px;
  font-size: inherit;
}
.btn:focus {
  box-shadow: none;
}
.btn-xs {
  font-size: 0.7rem;
  padding: 0.2rem 0.4rem;
}
.btn-group-sm > .btn,
.btn-sm {
  font-size: .8rem;
}
.float-e-margins .btn {
  margin-bottom: 5px;
}
.btn-w-m {
  min-width: 120px;
}
.btn-primary.btn-outline {
  color: #1ab394;
}
.btn-success.btn-outline {
  color: #1c84c6;
}
.btn-info.btn-outline {
  color: #23c6c8;
}
.btn-warning.btn-outline {
  color: #f8ac59;
}
.btn-danger.btn-outline {
  color: #ed5565;
}
.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff;
}
.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-primary {
  color: #fff;
  background-color: #1ab394;
  border-color: #1ab394;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus {
  background-color: #18a689;
  border-color: #18a689;
  color: #FFFFFF;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #18a689;
  border-color: #18a689;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #18a689;
  border-color: #18a689;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-success {
  color: #fff;
  background-color: #1c84c6;
  border-color: #1c84c6;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #1a7bb9;
  border-color: #1a7bb9;
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #1a7bb9;
  border-color: #1a7bb9;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1a7bb9;
  border-color: #1a7bb9;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-info {
  color: #fff;
  background-color: #23c6c8;
  border-color: #23c6c8;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #21b9bb;
  border-color: #21b9bb;
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #21b9bb;
  border-color: #21b9bb;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #21b9bb;
  border-color: #21b9bb;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-default {
  color: inherit;
  background: white;
  border: 1px solid #e7eaec;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default,
.btn-default:active:focus,
.btn-default:active:hover,
.btn-default.active:hover,
.btn-default.active:focus {
  color: inherit;
  border: 1px solid #d2d2d2;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}
.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default.active[disabled],
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
  color: #cacaca;
}
.btn-warning {
  color: #ffffff;
  background-color: #f8ac59;
  border-color: #f8ac59;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus {
  color: #ffffff;
  background-color: #f7a54a;
  border-color: #f7a54a;
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #ffffff;
  background-color: #f7a54a;
  border-color: #f7a54a;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #f7a54a;
  border-color: #f7a54a;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-danger {
  color: #fff;
  background-color: #ed5565;
  border-color: #ed5565;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #ec4758;
  border-color: #ec4758;
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #ec4758;
  border-color: #ec4758;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ec4758;
  border-color: #ec4758;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-link {
  color: inherit;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
  color: #1ab394;
  text-decoration: none;
}
.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
  background-image: none;
  box-shadow: none;
}
.btn-link.disabled,
.btn-link.disabled:hover,
.btn-link.disabled:focus,
.btn-link.disabled:active,
.btn-link.disabled.active,
.btn-link[disabled],
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
.btn-link[disabled]:active,
.btn-link.active[disabled],
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link.active {
  color: #cacaca;
}
.btn-white {
  color: inherit;
  background: white;
  border: 1px solid #e7eaec;
}
.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white,
.btn-white:active:focus,
.btn-white:active:hover,
.btn-white.active:hover,
.btn-white.active:focus {
  color: inherit;
  border: 1px solid #d2d2d2;
}
.btn-white:active,
.btn-white.active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  background-image: none;
}
.btn-white.disabled,
.btn-white.disabled:hover,
.btn-white.disabled:focus,
.btn-white.disabled:active,
.btn-white.disabled.active,
.btn-white[disabled],
.btn-white[disabled]:hover,
.btn-white[disabled]:focus,
.btn-white[disabled]:active,
.btn-white.active[disabled],
fieldset[disabled] .btn-white,
fieldset[disabled] .btn-white:hover,
fieldset[disabled] .btn-white:focus,
fieldset[disabled] .btn-white:active,
fieldset[disabled] .btn-white.active {
  color: #cacaca;
}
.form-control,
.form-control:focus,
.has-error .form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.navbar-collapse,
.navbar-form,
.navbar-form-custom .form-control:focus,
.navbar-form-custom .form-control:hover,
.open .btn.dropdown-toggle,
.panel,
.popover,
.progress,
.progress-bar {
  box-shadow: none;
}
.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s;
}
.btn-rounded {
  border-radius: 50px;
}
.btn-large-dim {
  width: 90px;
  height: 90px;
  font-size: 42px;
}
button.dim {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding-top: 6px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 20px !important;
}
button.dim:active {
  top: 3px;
}
button.btn-primary.dim {
  box-shadow: inset 0 0 0 #16987e, 0 5px 0 0 #16987e, 0 10px 5px #999999 !important;
}
button.btn-primary.dim:active {
  box-shadow: inset 0 0 0 #16987e, 0 2px 0 0 #16987e, 0 5px 3px #999999 !important;
}
button.btn-default.dim {
  box-shadow: inset 0 0 0 #b3b3b3, 0 5px 0 0 #b3b3b3, 0 10px 5px #999999 !important;
}
button.btn-default.dim:active {
  box-shadow: inset 0 0 0 #b3b3b3, 0 2px 0 0 #b3b3b3, 0 5px 3px #999999 !important;
}
button.btn-warning.dim {
  box-shadow: inset 0 0 0 #f79d3c, 0 5px 0 0 #f79d3c, 0 10px 5px #999999 !important;
}
button.btn-warning.dim:active {
  box-shadow: inset 0 0 0 #f79d3c, 0 2px 0 0 #f79d3c, 0 5px 3px #999999 !important;
}
button.btn-info.dim {
  box-shadow: inset 0 0 0 #1eacae, 0 5px 0 0 #1eacae, 0 10px 5px #999999 !important;
}
button.btn-info.dim:active {
  box-shadow: inset 0 0 0 #1eacae, 0 2px 0 0 #1eacae, 0 5px 3px #999999 !important;
}
button.btn-success.dim {
  box-shadow: inset 0 0 0 #1872ab, 0 5px 0 0 #1872ab, 0 10px 5px #999999 !important;
}
button.btn-success.dim:active {
  box-shadow: inset 0 0 0 #1872ab, 0 2px 0 0 #1872ab, 0 5px 3px #999999 !important;
}
button.btn-danger.dim {
  box-shadow: inset 0 0 0 #ea394c, 0 5px 0 0 #ea394c, 0 10px 5px #999999 !important;
}
button.btn-danger.dim:active {
  box-shadow: inset 0 0 0 #ea394c, 0 2px 0 0 #ea394c, 0 5px 3px #999999 !important;
}
button.dim:before {
  font-size: 50px;
  line-height: 1em;
  font-weight: normal;
  color: #fff;
  display: block;
  padding-top: 10px;
}
button.dim:active:before {
  top: 7px;
  font-size: 50px;
}
.btn:focus {
  outline: none !important;
}
.label {
  background-color: #d1dade;
  color: #5e5e5e;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  padding: 3px 8px;
  text-shadow: none;
  border-radius: 0.25em;
  line-height: 1;
  white-space: nowrap;
}



/* Select2 custom styles */
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  border-color: #e7eaec;
}
.select2-container--default.select2-container--focus .select2-selection--single,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #1ab394;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #1ab394;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #e7eaec;
}
.select2-dropdown {
  border-color: #e7eaec;
}
.select2-dropdown input:focus {
  outline: none;
}
.select2-selection {
  outline: none;
}
.ui-select-container.ui-select-bootstrap .ui-select-choices-row.active > a {
  background-color: #1ab394;
}

body {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #2f4050;
  font-size: 1rem;
  color: #676a6c;
  overflow-x: hidden;
}
html,
body {
  height: 100%;
}
body.full-height-layout #wrapper,
body.full-height-layout #page-wrapper {
  height: 100%;
}
#page-wrapper {
  min-height: 100vh;
}

/* WRAPPERS */
#wrapper {
  width: 100%;
  overflow-x: hidden;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  max-width: 1920px;
  margin: 0px auto;
}
.wrapper {
  padding: 0 20px;
}
.wrapper-content {
  padding: 20px 10px 40px;
}
#page-wrapper {
  padding: 0 15px;
  position: relative !important;
  flex-shrink: 1;
  width: calc(100%);
}
@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
  }
}
.title-action {
  text-align: right;
  padding-top: 30px;
}
.ibox-content h1,
.ibox-content h2,
.ibox-content h3,
.ibox-content h4,
.ibox-content h5,
.ibox-title h1,
.ibox-title h2,
.ibox-title h3,
.ibox-title h4,
.ibox-title h5 {
  margin-top: 5px;
}
ul.unstyled,
ol.unstyled {
  list-style: none outside none;
  margin-left: 0;
}
.big-icon {
  font-size: 160px !important;
  color: #e5e6e7;
}
/* FOOTER */
.footer {
  background: none repeat scroll 0 0 white;
  border-top: 1px solid #e7eaec;
  bottom: 0;
  left: 0;
  padding: 10px 20px;
  position: absolute;
  right: 0;
}
.footer.fixed_full {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 20px;
  background: white;
  border-top: 1px solid #e7eaec;
}
.footer.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 20px;
  background: white;
  border-top: 1px solid #e7eaec;
  margin-left: 220px;
}
body.mini-navbar .footer.fixed,
body.body-small.mini-navbar .footer.fixed {
  margin: 0 0 0 70px;
}
body.mini-navbar.fixed-sidebar .footer.fixed {
  margin: 0;
}
body.mini-navbar.canvas-menu .footer.fixed,
body.canvas-menu .footer.fixed {
  margin: 0 !important;
}
body.fixed-sidebar.body-small.mini-navbar .footer.fixed {
  margin: 0 0 0 220px;
}
body.body-small .footer.fixed {
  margin-left: 0;
}
/* PANELS */
.panel-title > .small,
.panel-title > .small > a,
.panel-title > a,
.panel-title > small,
.panel-title > small > a {
  color: inherit;
}
.page-heading {
  border-top: 0;
  padding: 0 10px 20px 10px;
}
.panel-heading h1,
.panel-heading h2 {
  margin-bottom: 5px;
}
.panel-body {
  padding: 15px;
}
/* Bootstrap 3.3.x panels */
.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
}
.panel-heading {
  color: white;
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel-footer {
  padding: 10px 15px;
  border-top: 1px solid #e7eaec;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #e7eaec;
}
.panel-default {
  border-color: #e7eaec;
}
.panel-group .panel + .panel {
  margin-top: 5px;
}
.panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px;
}
/* TABLES */
.table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th {
  border-top: 0;
}
.table-bordered {
  border: 1px solid #EBEBEB;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  background-color: #F5F5F6;
  border-bottom-width: 1px;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #e7e7e7;
}
.table > thead > tr > th {
  border-bottom: 1px solid #DDDDDD;
  vertical-align: bottom;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 1px solid #e7eaec;
  line-height: 1.42857;
  padding: 8px;
  vertical-align: top;
}
.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}
.ibox.collapsed .ibox-content {
  display: none;
}
.ibox.collapsed .fa.fa-chevron-up:before {
  content: "\f078";
}
.ibox.collapsed .fa.fa-chevron-down:before {
  content: "\f077";
}
.ibox:after,
.ibox:before {
  display: table;
}
.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #ffffff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 2px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 15px 90px 8px 15px;
  min-height: 48px;
  position: relative;
  clear: both;
}
.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}
.ibox-footer {
  color: inherit;
  border-top: 1px solid #e7eaec;
  font-size: 90%;
  background: #ffffff;
  padding: 10px 15px;
}
table.table-mail tr td {
  padding: 12px;
}
.table-mail .check-mail {
  padding-left: 20px;
}
.table-mail .mail-date {
  padding-right: 20px;
}
.star-mail,
.check-mail {
  width: 40px;
}
.unread td a,
.unread td {
  font-weight: 600;
  color: inherit;
}
.read td a,
.read td {
  font-weight: normal;
  color: inherit;
}
.unread td {
  background-color: #f9f8f8;
}
.ibox-content {
  clear: both;
}
.ibox-heading {
  background-color: #f3f6fb;
  border-bottom: none;
}
.ibox-heading h3 {
  font-weight: 200;
  font-size: 24px;
}
.ibox-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  float: none;
}
.ibox-title .label {
  margin-left: 4px;
}
.ibox-title .pull-right {
  position: absolute;
  right: 15px;
  top: 15px;
}
.ibox-tools {
  display: block;
  float: none;
  margin-top: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 0;
  text-align: right;
}
.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: #c4c4c4 !important;
}
.ibox-tools a.btn-primary {
  color: #fff !important;
}
.ibox-tools .dropdown-menu > li > a {
  padding: 4px 10px;
  font-size: 12px;
  color: #676a6c !important;
}
.ibox .ibox-tools.open > .dropdown-menu {
  left: auto;
  right: 0;
}
.ibox-tools .dropdown-toggle::after {
  display: none;
}
.dropdown-item {
  width: auto;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: inherit;
  color: inherit;
}
/* BACKGROUNDS */
.gray-bg,
.bg-muted {
  background-color: #f3f3f4;
}
.white-bg {
  background-color: #ffffff;
}
.blue-bg,
.bg-success {
  background-color: #1c84c6 !important;
  color: #ffffff;
}
.navy-bg,
.bg-primary {
  background-color: #1ab394 !important;
  color: #ffffff;
}
.lazur-bg,
.bg-info {
  background-color: #23c6c8 !important;
  color: #ffffff;
}
.yellow-bg,
.bg-warning {
  background-color: #f8ac59 !important;
  color: #ffffff;
}
.red-bg,
.bg-danger {
  background-color: #ed5565 !important;
  color: #ffffff;
}
.black-bg {
  background-color: #262626;
}
.panel-primary {
  border-color: #1ab394;
}
.panel-primary > .panel-heading {
  background-color: #1ab394;
  border-color: #1ab394;
}
.panel-success {
  border-color: #1c84c6;
}
.panel-success > .panel-heading {
  background-color: #1c84c6;
  border-color: #1c84c6;
  color: #ffffff;
}
.panel-info {
  border-color: #23c6c8;
}
.panel-info > .panel-heading {
  background-color: #23c6c8;
  border-color: #23c6c8;
  color: #ffffff;
}
.panel-warning {
  border-color: #f8ac59;
}
.panel-warning > .panel-heading {
  background-color: #f8ac59;
  border-color: #f8ac59;
  color: #ffffff;
}
.panel-danger {
  border-color: #ed5565;
}
.panel-danger > .panel-heading {
  background-color: #ed5565;
  border-color: #ed5565;
  color: #ffffff;
}
.progress-bar {
  background-color: #1ab394;
}
.progress-small,
.progress-small .progress-bar {
  height: 10px;
}
.progress-small,
.progress-mini {
  margin-top: 5px;
}
.progress-mini,
.progress-mini .progress-bar {
  height: 5px;
  margin-bottom: 0;
}
.progress-bar-navy-light {
  background-color: #3dc7ab;
}
.progress-bar-success {
  background-color: #1c84c6;
}
.progress-bar-info {
  background-color: #23c6c8;
}
.progress-bar-warning {
  background-color: #f8ac59;
}
.progress-bar-danger {
  background-color: #ed5565;
}
.panel-title {
  font-size: inherit;
}
.jumbotron {
  border-radius: 6px;
  padding: 40px;
}
.jumbotron h1 {
  margin-top: 0;
}
/* COLORS */
.text-navy {
  color: #1ab394 !important;
}
.text-primary {
  color: inherit !important;
}
.text-success {
  color: #1c84c6 !important;
}
.text-info {
  color: #23c6c8 !important;
}
.text-warning {
  color: #f8ac59 !important;
}
.text-danger {
  color: #ed5565 !important;
}
.text-muted {
  color: #888888 !important;
}
.text-white {
  color: #ffffff;
}



@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    min-height: 100vh;
  }
  .navbar-static-side {
    z-index: 2001;
    width: 220px;
  }
  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    margin-left: auto;
  }
}
@media (max-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 0;
    min-height: 100vh;
    width: 100%;
  }
}

@media (max-width: 350px) {

  .ibox-tools {
    float: none;
    text-align: left;
    display: inline-block;
  }
}

.nav_title {
  font-weight: 700;
  padding: 0 15px;
  text-transform: uppercase;
}

.footer-icon{
  vertical-align: middle;
  margin: 0 4px;
}

.loginscreen.middle-box{
  max-width: 400px;
  z-index: 100;
  margin: 0 auto;
  padding-top: 40px;
  position: relative;
}

.LoadingIcon{
  position: absolute;
  width: 100%;
  bottom: 0;
}

.ErrorMassge{
  color: red; 
  margin-bottom: 15px;
}